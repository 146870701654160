<template>
    <div class="container_con">
        <el-card>
            <div class="search">
                <div>
                    <el-button type="primary" @click="$router.push('/addDepartment')" v-if="have_auth('/department/add')">+ 新增</el-button>
                </div>
                <div>
                    <el-input icon="el-icon-search" v-model="queryParams.title" @keyup.enter.native="handleQuery" placeholder="请输入搜索内容" clearable
                        style="width:300px;margin-right:10px"></el-input>
                    <el-button type="primary" @click="handleQuery()">搜索</el-button>
                    <el-button @click="resetQuery()">重置</el-button>
                </div>
            </div>
            <div style="margin-top:20px">
                <el-table :data="list" v-loading="loading" style="width: 100%" row-key="id"
        :tree-props="{children: '_child', hasChildren: 'hasChildren'}">
                    <el-table-column prop="title" label="标题" />
                    <el-table-column prop="remark" label="描述" />
                    <!-- <el-table-column label="父级">
                        <template v-slot="scope">
                          {{scope.pid || '暂无'}}
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="sort" label="排序" />
                    <el-table-column fixed="right" label="操作" width="150">
                        <template v-slot="scope">
                            <el-button type="text" size="small" @click="handleUpdate(scope.row)" v-if="have_auth('/department/edit')">编辑</el-button>
                            <el-button type="text" size="small" @click="handleDataScope(scope.row)" v-if="have_auth('/department/auth')">设置权限</el-button>
                            <el-button type="text" size="small" @click="handleDelete(scope.row)" v-if="have_auth('/department/del')">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
        <el-dialog v-model="openDataScope" title="设置权限" width="800px" center :close-on-click-modal="false">
            <!-- <el-select v-model="newlist" multiple placeholder="请选择" style="width:100%;margin-bottom:10px"
                @remove-tag="splicenews">
            </el-select> -->
            <div ><el-checkbox v-model="menuNodeAll" @change="handleCheckedTreeNodeAll($event)">全选/全不选</el-checkbox></div>
            <div style=" border: 1px solid #eee; border-radius: 0px;" class="org_sele_tree">
              <el-scrollbar hidex style="position:relative;height: 500px;">
            
                  <el-tree
                    :default-expand-all="true"
                    :data="menuOptions"
                    show-checkbox
                    check-on-click-node
                    ref="menu"
                    node-key="id"
                    empty-text="加载中，请稍后"
                    :props="defaultProps"
                  >
                    <template  #default="{ node, data }">
                        <span class="custom-tree-node" >
                            <span :class="'js_node_'+data.type" :data-id="data.id">{{ data.title }}</span>
                        </span>
                    </template>
                  </el-tree>
              </el-scrollbar>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="cancelDataScope">取消</el-button>
                    <el-button type="primary" :loading="is_ajax" @click="submitDataScope">提交</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
    <!-- <pagination
      v-show="total>0"
      :total="total"
      v-model:page="queryParams.page"
      v-model:limit="queryParams.size"
      @pagination="getList"
    /> -->
</template>

<script>
    import $ from "jquery";
    export default {
        name: "department",
        data() {
            return {
                // 是否显示弹出层（数据权限）
                openDataScope: false,
                menuExpand: false,
                menuNodeAll: false,
                deptExpand: true,
                deptNodeAll: false,
                menuOptions:[],
                // 列表模板
                loading: false,
                // 查询参数
                queryParams: {
                    page: 1,
                    size: 10,
                    title: ''
                },
                total:0,
                list:[],
                op_id:'',
                defaultProps: {
                  children: "_child",
                  label: "title"
                },
                is_ajax:false
            };
        },
        created() {
            this.queryParams=window[this.$route.path] || {
              page: 1,
              size: 10,
              title: ''
            }
            this.getList();
        },
        watch: {
          $route(to,form) {
              window[form.path]=this.queryParams
          },
        },
        methods: {
            resetQuery(){
                  this.queryParams= {
                      page: 1,
                      size: 10,
                      title: ''
                  }
                  this.getList()
            },
            // 所有菜单节点数据
            getMenuAllCheckedKeys() {
              // 目前被选中的菜单节点
              let checkedKeys = this.$refs.menu.getCheckedKeys();
              // 半选中的菜单节点
              let halfCheckedKeys = this.$refs.menu.getHalfCheckedKeys();
              checkedKeys.unshift.apply(checkedKeys, halfCheckedKeys);
              return checkedKeys;
            },
            
            // 取消按钮（数据权限）
            cancelDataScope() {
              this.openDataScope = false;
              this.reset();
            },
            // 表单重置
            reset() {
              if (this.$refs.menu != undefined) {
                this.$refs.menu.setCheckedKeys([]);
              }
              this.menuExpand = false;
              this.menuNodeAll = false;
              this.deptExpand = true;
              this.deptNodeAll = false;
            },
            // 树权限（全选/全不选）
            handleCheckedTreeNodeAll(value) {
              this.$refs.menu.setCheckedNodes(value ? this.menuOptions: []);
            },
            /** 分配数据权限操作 */
            handleDataScope(row) {
              this.op_id=row.id;
              const _this=this;
              
              _this.$httpGet("/backend/Menu/index", {}).then((res) => {
                  if (res.status == 200) {
                      let check_ids=row.menu_ids?row.menu_ids.split(','):[];
                      this.menuOptions = res.data.menu_tree;
                      this.$nextTick(() => {
                        for(let i=0;i<check_ids.length;i++){
                          this.$refs.menu.setChecked(check_ids[i],true)
                        }
                        this.$nextTick(() => {
                          $(".js_node_3")
                          .parent(".custom-tree-node")
                          .parent(".el-tree-node__content")
                          .parent(".el-tree-node")
                          .parent(".el-tree-node__children")
                          .addClass("pingpu_display_wrap");
                        });
                      });
                  } else {
                      _this.$message.error(res.message);
                  }
              }).catch((err) => {
                  console.log(err);
              });
              this.openDataScope = true;
            },
            
            /** 提交按钮（数据权限） */
            submitDataScope: function() {
              if(this.is_ajax){return false;}
              let data={
                id:this.op_id,
                access_id:this.getMenuAllCheckedKeys()
              }
              this.is_ajax=true;
              this.$httpPost("/backend/SysRole/access", data).then((res) => {
                  if (res.status == 200) {
                      this.$message.success('设置成功');
                      this.reset();
                      this.openDataScope=false;
                      this.getList();
                  } else {
                      this.$message.error(res.message);
                  }
                  this.is_ajax=false;
              }).catch((err) => {
                  console.log(err);
                  this.is_ajax=false;
              });
            },
            
            
            /** 修改按钮操作 */
            handleUpdate(row) {
              this.$router.push('/addDepartment?id='+row.id)
            },
            /** 删除按钮操作 */
            handleDelete(row) {
              const _this=this;
              const ids = row.id || this.ids;
              this.$confirm('是否确认删除?', "警告", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning"
                }).then(function() {
                  
                  _this.$httpGet("/backend/sys.Department/del", {id:ids}).then((res) => {
                      if (res.status == 200) {
                          _this.getList();
                          _this.$message.success(res.message);
                      } else {
                          _this.$message.error(res.message);
                      }
                  }).catch((err) => {
                      console.log(err);
                  });
                })
            },
            /** 查询列表 */
            getList() {
                this.loading = true;
                this.$httpGet("/backend/sys.Department/index", this.queryParams).then((res) => {
                    if (res.status == 200) {
                        this.loading = false;
                        this.list = res.data.list;
                        this.total = res.data.total;
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            },
            /** 搜索按钮操作 */
            handleQuery() {
                this.queryParams.page = 1;
                this.getList();
            }
        },
    };
</script>

<style lang="scss" scoped></style>
